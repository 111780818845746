<!--
 * @Date: 2024-09-21 22:30:21
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-09-25 10:39:38
 * @FilePath: \zyt-mobile-frontend\src\components\cinemaCompanyCardPicker\index.vue
-->
<template>
  <div>
    <!-- // 放映设备选择器弹窗 -->
    <van-popup position="bottom" v-model="showEquipmentPicker" close-icon>
      <van-search
        v-model="equipmentKey"
        placeholder="搜索设备编码"
        @search="onSearchShowEquipment"
      />
      <van-picker
        show-toolbar
        :columns="equipmentOptions"
        @confirm="onConfirmEquipment"
        @cancel="showEquipmentPicker = false"
        v-if="equipmentOptions.length"
      />
      <van-empty description="请输入设备编码搜索或刷新设备数据" v-else />
    </van-popup>
  </div>
</template>

<script>
import {api} from '@/api'
import { Toast } from 'vant'
export default {
  data() {
    return {
      showEquipmentPicker: false,
      equipmentKey: '',
      equipmentOptions: [],
    }
  },
  methods: {
    // 显示设备选择器弹窗
    show(){
      this.showEquipmentPicker = true
      // this.equipmentKey = ''
      // this.equipmentOptions = []
    },
    // 隐藏设备选择器弹窗
    hide(){
      this.showEquipmentPicker = false
      this.equipmentKey = ''
      this.equipmentOptions = []
    },
    // 选择放映设备
    onConfirmEquipment(option) {
      // this.tempPlanData.selectedEquipment = option
      this.showEquipmentPicker = false
      this.hide()
      this.$emit('confirm', option)
    },
    // 设备搜索
    onSearchShowEquipment(key) {
      if (key.length < 4) {
        Toast('请输入至少4位设备编码')
        return
      }
      const query = {
        cardCode: key,
      }
      api.filmmaker.getFilmDeviceList(query).then((res) => {
        console.log({res});
        const {success, result} = res
        if (success) {
          // console.log(result.records.map((item) => item.cardcode));
          
          this.equipmentOptions = result.records.map((item) => item.cardcode)
        } else {
          Toast(result)
        }
      })
      
      
    },
  }


}
</script>

<style>

</style>